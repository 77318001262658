const key_name = "sc_token";

export const getAccessToken = () =>
  typeof localStorage !== "undefined" && localStorage.getItem(key_name);

export const setAccessToken = token =>
  typeof localStorage !== "undefined" && localStorage.setItem(key_name, token);

export const unsetAccessToken = () =>
  typeof localStorage !== "undefined" && localStorage.removeItem(key_name);
