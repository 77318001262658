import { action, observable } from "mobx";

import State from "./bases/State";

class User extends State {
  @observable user = null;
  @observable authorized = false;
  @observable loaded = false;
  @observable loading = false;
  @observable list = [];

  constructor(API) {
    super(API);

    this.authorized = this.API.loggedIn;

    this.API.addListener("loggedOut", this.updatedAuthorizedStatus);

    this.get();
  }

  updatedAuthorizedStatus = () => {
    this.authorized = this.API.loggedIn;
  };

  authenticate = async (username, password) => {
    await this.API.post(
      "/oauth/token",
      {
        grant_type: "password",
        client_id: process.env.REACT_APP_API_APP_ID,
        client_secret: process.env.REACT_APP_API_APP_SECRET,
        username,
        password
      },
      true
    );
    await this.get();

    return this.user;
  };

  register = async (form) => {
    const reg = await this.API.post(
      "/api/user/signup",
      new FormData(form),
      true
    );
    await this.get();

    return reg;
  };

  setupSockets = () => {
    this.API.sockets.init(this.API.access_token, this.user.id);
  };

  @action
  get = async () => {
    try {
      this.loading = true;
      this.user = await this.API.get("/api/user");
      this.authorized = true;
      this.loaded = true;
      this.loading = false;

      return this.user;
    } catch (error) {
      this.loaded = true;
      this.loading = false;
      return null;
    }
  };

  @action
  logout = () => {
    this.authorized = false;
    this.user = null;

    this.API.unsetToken();
  };

  @action
  getList = async (force = false) => {
    if (!force && this.list && this.list.length > 0) return this.list;

    try {
      this.loading = true;
      this.list = await this.API.get("/api/users");
      this.loaded = true;
      this.loading = false;

      return this.list;
    } catch (error) {
      this.loaded = true;
      this.loading = false;
      return null;
    }
  };

  @action
  delete = async id => {
    if (!id) return false;

    if (window.confirm("Are you sure?")) {
      try {
        await this.API.delete(`/api/users/${id}`);
        await this.getList(true);

        return true;
      } catch (error) {
        // handle error
        return false;
      }
    }
  };

  create = async form => {
    try {
      await this.API.post("/api/users", form);

      await this.getList(true);

      return true;
    } catch (error) {
      // handle error
      return false;
    }
  };
  update = async (id, form) => {
    try {
      await this.API.post(`/api/users/${id}`, form);
      await this.getList(true);

      return true;
    } catch (error) {
      // handle error
      return false;
    }
  };
}

export default User;
