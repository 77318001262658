import { Button, Heading, Icon, Pane } from "evergreen-ui";
import { inject, observer } from "mobx-react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";

import LayoutItemEdit from "../../components/LayoutItemEdit";
import React from "react";
import { Link, useMatch } from "@reach/router";

const DragHandle = SortableHandle(() => (
  <Icon icon="drag-handle-vertical" color="muted" marginRight={16} />
));

const ChildItems = SortableElement(
  ({ parent, item = parent, setToEdit, setCreateVisible, destroy, child }) => (
    <Pane
      key={child.id}
      width={125}
      height={125}
      marginRight={16}
      border="default"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      transform="translateY(0)"
    >
      <div>{child.entity && child.entity.mark}</div>
      <div>{child.title}</div>
      <Pane marginTop={10}>
        <DragHandle />
        <Icon
          onClick={() => {
            child.parent = item;

            setToEdit(child);
            setCreateVisible(true);
          }}
          icon="edit"
          color="muted"
        />
        <Icon
          onClick={() => destroy(child.id)}
          icon="cross"
          color="muted"
          marginLeft={16}
        />
      </Pane>
    </Pane>
  )
);

const ChildLayouts = SortableContainer(
  ({
    layoutId,
    siteId,
    item,
    setToEdit,
    setCreateVisible,
    destroy,
    collectionIndex
  }) => {
    return layoutId ? (
      <Pane display="flex" marginTop={16}>
        {item.children.map((child, index) => (
          <ChildItems
            key={`child-item-${child.id}`}
            index={index}
            collection={collectionIndex}
            parent={item}
            child={child}
            setToEdit={setToEdit}
            setCreateVisible={setCreateVisible}
            destroy={destroy}
          />
        ))}
        <Pane
          width={125}
          height={125}
          marginRight={16}
          border="default"
          display="flex"
          justifyContent="center"
          alignItems="center"
          cursor="pointer"
          onClick={() => {
            setToEdit({ type: "children", parent: { ...item, siteId } });
            setCreateVisible(true);
          }}
        >
          <Icon icon="add" />
        </Pane>
      </Pane>
    ) : null;
  }
);

const ParentsItem = SortableElement(
  ({
    parent,
    siteId,
    layoutId,
    item = parent,
    setToEdit,
    setCreateVisible,
    destroy,
    setAsMainPage,
    collectionIndex,
    onChildrenSortEnd
  }) => (
      <Pane
        key={item.id}
        width="calc(80vw - 4rem)"
        padding="1rem"
        marginTop={16}
        border="default"
      >
        <Heading size={600}>
          <DragHandle />
          <Link to={`/app/site/${item.site_id}/layout/${item.id}`}>
            {!layoutId ? `#${item.id} – ` : ""}
            {item.title}
          </Link>
          <Icon
            onClick={() => {
              item.parent = item.parent
                ? item.parent
                : {
                  title: "Текущий шаблон",
                  id: layoutId ? layoutId : null
                };

              setToEdit(item);
              setCreateVisible(true);
            }}
            icon="edit"
            color="muted"
            marginLeft={16}
          />
          <Icon
            onClick={() => destroy(item.id)}
            icon="cross"
            color="muted"
            marginLeft={16}
          />
          {!layoutId && !item.main_page && (
            <Icon
              onClick={() => setAsMainPage(item.id)}
              icon="layout-grid"
              color="muted"
              marginLeft={16}
            />
          )}
        </Heading>
        <ChildLayouts
          siteId={siteId}
          item={item}
          layoutId={layoutId}
          collectionIndex={collectionIndex}
          setToEdit={setToEdit}
          setCreateVisible={setCreateVisible}
          destroy={destroy}
          onSortEnd={onChildrenSortEnd}
          axis="x"
          useDragHandle
        />
      </Pane>
    )
);

const ParentsLayout = SortableContainer(
  ({
    layout,
    layoutId,
    setToEdit,
    setCreateVisible,
    destroy,
    toEdit,
    createVisible,
    setAsMainPage,
    onChildrenSortEnd
  }) => {
    const match = useMatch('/app/site/:siteId/*');

    return (
      <div>
        {layout.map((item, collectionIndex) => (
          <ParentsItem
            key={item.id}
            index={collectionIndex}
            item={item}
            layoutId={layoutId}
            collectionIndex={collectionIndex}
            setToEdit={setToEdit}
            setCreateVisible={setCreateVisible}
            destroy={destroy}
            toEdit={toEdit}
            createVisible={createVisible}
            onChildrenSortEnd={onChildrenSortEnd}
            setAsMainPage={setAsMainPage}
            siteId={match.siteId}
          />
        ))}
        <Button
          iconBefore="add"
          marginTop={16}
          onClick={() => {
            setToEdit({
              type: layoutId ? "section" : "template",
              parent: {
                title: "Текущий шаблон",
                siteId: match.siteId,
                id: layoutId ? layoutId : null
              }
            });
            setCreateVisible(true);
          }}
        >
          Add block
        </Button>

        <LayoutItemEdit
          visible={createVisible}
          item={toEdit}
          close={() => setCreateVisible(false)}
        />
      </div>
    );
  }
);

const LayoutPage = ({
  layoutId,
  layout,
  load,
  destroy,
  updateOrder,
  setAsMainPage
}) => {
  const match = useMatch('/app/site/:siteId/*');

  const [createVisible, setCreateVisible] = React.useState(false);
  const [toEdit, setToEdit] = React.useState({});
  load(match.siteId, layoutId);

  const onChildrenSortEnd = ({ collection, newIndex, oldIndex }) => {
    const itemId = layout[collection].children[oldIndex].id;
    updateOrder(itemId, newIndex);
  };

  const onSortEnd = ({ newIndex, oldIndex }) => {
    const itemId = layout[oldIndex].id;
    updateOrder(itemId, newIndex);
  };

  return (
    <ParentsLayout
      layout={layout}
      layoutId={layoutId}
      createVisible={createVisible}
      toEdit={toEdit}
      setCreateVisible={setCreateVisible}
      setToEdit={setToEdit}
      destroy={destroy}
      onSortEnd={onSortEnd}
      onChildrenSortEnd={onChildrenSortEnd}
      setAsMainPage={setAsMainPage}
      axis="y"
      useDragHandle
    />
  );
};

export default inject(({ store }) => ({
  layout: store.layout.list,
  load: store.layout.get,
  destroy: store.layout.delete,
  updateOrder: store.layout.updateOrder,
  setAsMainPage: store.layout.setAsMainPage
}))(observer(LayoutPage));
