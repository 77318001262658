import { Button, Heading, Pane } from "evergreen-ui";
import { inject, observer } from "mobx-react";

import React from "react";
import { selectFiles } from "../../libs/files";

class PhotosPage extends React.Component {
  state = {
    entity: null
  };

  async componentDidMount() {
    const { id, getById } = this.props;

    const entity = await getById(id);

    this.setState({ entity });
  }

  remove = async photoId => {
    const { id, remove, getById } = this.props;

    await remove(photoId);
    const entity = await getById(id);

    this.setState({ entity });
  };

  upload = async () => {
    const { entity } = this.state;
    const { upload, getById, id } = this.props;

    const form = await selectFiles();

    for (let pair of form.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    await upload(entity.id, form);
    const updatedEntity = await getById(id);

    this.setState({ entity: updatedEntity });
  };

  render() {
    const { entity } = this.state;

    return (
      <div>
        {entity && (
          <Pane key={entity.id} marginBottom={32}>
            <Heading size={600}>{entity.name}</Heading>
            <Button
              iconBefore="add"
              marginTop={16}
              marginBottom={16}
              onClick={this.upload}
            >
              Add photo
            </Button>
            <Pane display="flex" flexDirection="row" flexWrap="wrap">
              {entity &&
                entity.photos &&
                entity.photos.map(photo => (
                  <Pane
                    key={photo.id}
                    marginBottom={16}
                    marginTop={16}
                    border="default"
                    padding={16}
                  >
                    <div>
                      <img
                        style={{ maxWidth: 250 }}
                        key={photo.url}
                        src={`${process.env.REACT_APP_API_ENDPOINT}/${photo.url}`}
                        alt=""
                      />
                    </div>
                    <Button
                      iconBefore="trash"
                      marginRight={16}
                      appearance="minimal"
                      intent="danger"
                      onClick={() => this.remove(photo.id)}
                    >
                      Remove
                    </Button>
                  </Pane>
                ))}
            </Pane>
          </Pane>
        )}
      </div>
    );
  }
}

export default inject(({ store }) => ({
  getById: store.entity.loadById,
  remove: store.entity.deletePhoto,
  upload: store.entity.storePhotos
}))(observer(PhotosPage));
