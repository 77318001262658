import React, { useEffect, useMemo } from "react";

import { inject, observer } from "mobx-react";
import { Link, useLocation, useMatch, navigate } from "@reach/router";
import {
  Avatar,
  Pane,
  Badge,
  SelectMenu,
  Button,
  TabNavigation,
  Tab,
  SearchInput,
  Text,
  IconButton,
} from "evergreen-ui";

const siteNavItems = (siteId) => [
  { name: "Лейауты", link: `/app/site/${siteId}/layout` },
  { name: "Элементы", link: `/app/site/${siteId}/entities` },
  { name: "Настройки", link: `/app/site/${siteId}/users` },
];

const Header = ({ loadSites, sitesList, user }) => {
  const location = useLocation();
  const match = useMatch("/app/site/:siteId/*");

  useEffect(() => {
    loadSites();
  }, [loadSites]);

  const siteNavigation = useMemo(() => match && siteNavItems(match.siteId), [
    match,
  ]);

  const currentSite = useMemo(() => {
    if (!match) return null;
    return sitesList.find((item) => item.id === parseInt(match.siteId, 10));
  }, [match, sitesList]);

  return (
    <Pane
      elevation={1}
      border="default"
      width="100vw"
      position="fixed"
      top={0}
      left={0}
      height={60}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      paddingLeft={47}
      paddingRight={20}
    >
      <Pane display="flex" alignItems="center">
        <Badge color="yellow">Dough</Badge>
        {match && (
          <>
            <IconButton
              is={Link}
              to="/app"
              icon="arrow-left"
              height={32}
              marginLeft={100}
              marginRight={10}
            />
            <Pane marginRight={30}>
              <SelectMenu
                width={250}
                hasTitle={false}
                hasFilter={false}
                title={currentSite ? currentSite.name : "Ваши сайты"}
                options={sitesList.map((site) => ({
                  label: site.name,
                  value: site.id,
                }))}
                selected={parseInt(match.siteId, 10)}
                onSelect={(item) => navigate(`/app/site/${item.value}/layout`)}
                emptyView={
                  <Pane
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text size={300}>У вас нет сайтов</Text>

                    <Button
                      display="flex"
                      justifyContent="space-between"
                      iconAfter="add"
                      marginTop={20}
                    >
                      Создать сайт
                    </Button>
                  </Pane>
                }
              >
                <Button
                  display="flex"
                  justifyContent="space-between"
                  width={250}
                  iconAfter="caret-down"
                >
                  {currentSite ? currentSite.name : "Ваши сайты"}
                </Button>
              </SelectMenu>
            </Pane>
            <TabNavigation>
              {siteNavigation.map((tab, index) => (
                <Tab
                  key={tab.link}
                  is={Link}
                  to={tab.link}
                  id={tab}
                  isSelected={location.pathname === tab.link}
                >
                  {tab.name}
                </Tab>
              ))}
            </TabNavigation>
          </>
        )}
      </Pane>
      <Pane display="flex" alignItems="center">
        <SearchInput placeholder="Поиск по контенту..." marginRight={50} />

        <Avatar name={user.name} size={32} />
      </Pane>
    </Pane>
  );
};

export default inject(({ store }) => ({
  loadSites: store.sites.getAll,
  sitesList: store.sites.list,
  user: store.user.user,
}))(observer(Header));
