import {
  Button,
  Menu,
  Pane,
  Popover,
  Position,
  Tablist,
  Tab,
} from "evergreen-ui";
import { inject, observer } from "mobx-react";

import EntityItemEdit from "../../components/EntityItemEdit";
import React from "react";
import { navigate, useMatch } from "@reach/router";

const EntitiesPage = ({ entities, load, destroy }) => {
  const match = useMatch("/app/site/:siteId/*");

  const [createVisible, setCreateVisible] = React.useState(false);
  const [toEdit, setToEdit] = React.useState({});
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  load(match ? match.siteId : "");

  return (
    <div>
      <Button
        iconBefore="add"
        marginBottom={16}
        onClick={() => setCreateVisible(true)}
      >
        Add entity
      </Button>
      <Pane height={120}>
        <Tablist marginBottom={16} flexBasis={240} marginRight={24}>
          {Object.keys(entities).map((tab, index) => (
            <Tab
              key={tab}
              id={tab}
              onSelect={() => setSelectedIndex(index)}
              isSelected={index === selectedIndex}
              aria-controls={`panel-${tab}`}
            >
              {tab}
            </Tab>
          ))}
        </Tablist>
        <Pane display="flex" marginTop={16} maxWidth="75vw" flexWrap="wrap">
          {Object.values(entities).map((category, tabIndex) =>
            category.map((entity) => (
              <Pane
                key={entity.id}
                width={200}
                height={150}
                marginRight={16}
                marginBottom={16}
                border="default"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                display={tabIndex === selectedIndex ? "flex" : "none"}
              >
                <span role="img" aria-label="emoji">
                  {entity.mark}
                </span>
                <div>
                  <Popover
                    position={Position.BOTTOM_LEFT}
                    content={
                      <Menu>
                        <Menu.Group>
                          <Menu.Item
                            icon="manual"
                            onSelect={() =>
                              navigate(
                                `${window.location.pathname}/${entity.id}/infoblocks`
                              )
                            }
                          >
                            Infoblocks...
                          </Menu.Item>
                          <Menu.Item
                            onSelect={() => {
                              setToEdit(entity);
                              setCreateVisible(true);
                            }}
                            icon="edit"
                          >
                            Edit...
                          </Menu.Item>
                        </Menu.Group>
                        <Menu.Divider />
                        <Menu.Item
                          onSelect={() =>
                            navigate(
                              `${window.location.pathname}/${entity.id}/photos`
                            )
                          }
                          icon="folder-open"
                        >
                          Photos
                        </Menu.Item>
                        <Menu.Item
                          onSelect={() =>
                            navigate(
                              `${window.location.pathname}/${entity.id}/relations`
                            )
                          }
                          icon="diagram-tree"
                        >
                          Relations
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Group>
                          <Menu.Item
                            onSelect={() => destroy(entity.id)}
                            icon="trash"
                            intent="danger"
                          >
                            Delete...
                          </Menu.Item>
                        </Menu.Group>
                      </Menu>
                    }
                  >
                    <Button marginTop={8} appearance="minimal" height={24}>
                      {entity.name} (#{entity.id})
                    </Button>
                  </Popover>
                </div>
              </Pane>
            ))
          )}
        </Pane>
      </Pane>
      <EntityItemEdit
        visible={createVisible}
        item={toEdit}
        siteId={match.siteId}
        close={() => {
          setToEdit({});
          setCreateVisible(false);
        }}
      />
    </div>
  );
};

export default inject(({ store }) => ({
  entities: store.entity.list,
  load: store.entity.get,
  destroy: store.entity.delete,
}))(observer(EntitiesPage));
