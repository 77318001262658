import {
  getAccessToken,
  setAccessToken,
  unsetAccessToken
} from "../libs/storage";

class API {
  access_token = null;
  loggedIn = false;
  endpoint = process.env.REACT_APP_API_ENDPOINT || "";
  headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  query = null;
  listeners = {};

  constructor() {
    this.updateToken();
  }

  addListener = (event, listener) => {
    if (!this.listeners[event]) this.listeners[event] = [];

    this.listeners[event].push(listener);
  };

  removeListener = (event, listener) => {
    if (this.listeners[event])
      this.listeners[event] = this.listeners[event].filter(
        item => item !== listener
      );
  };

  invokeListeners = event => {
    this.listeners[event] &&
      this.listeners[event].forEach(listener => listener());
  };

  reset = () => {
    this.query = null;

    this.updateToken();
  };

  updateToken = () => {
    const token = getAccessToken();

    if (token) {
      this.loggedIn = true;
      this.access_token = token;
      this.headers["Authorization"] = `Bearer ${token}`;

      this.invokeListeners("loggedIn");
    } else {
      this.loggedIn = false;
      this.invokeListeners("loggedOut");
    }
  };

  unsetToken = () => {
    unsetAccessToken();

    this.access_token = null;
    this.query = null;
    delete this.headers["Authorization"];

    this.updateToken();
  };

  processResponse = res => {
    if (res.access_token && res.token_type === "Bearer") {
      setAccessToken(res.access_token);
      this.updateToken();
    }

    if (res.error || res.status === "error") return Promise.reject(res);

    return res;
  };

  processBody = (method, body) => {
    let value;

    if (typeof body === "object") value = JSON.stringify(body);
    if (body instanceof FormData) value = body;
    if (method === "GET") value = undefined;

    return value;
  };

  processErrors = query => {
    if (query.ok) return query;

    if (query.status === 401) {
      this.unsetToken();
      return Promise.reject(401);
    }

    return query;
  };

  dynamicHeaders = (headers, body) => {
    if (body instanceof FormData) delete headers["Content-Type"];

    return headers;
  };

  request = async (
    url,
    method = "GET",
    body = {},
    headers = {},
    force = false
  ) => {
    if (!this.access_token && !force) return Promise.reject(null);

    this.query = await fetch(`${this.endpoint}${url}`, {
      method,
      body: this.processBody(method, body),
      headers: this.dynamicHeaders(
        {
          ...this.headers,
          ...headers
        },
        body
      )
    });

    this.query = await this.processErrors(this.query);
    this.query = await this.query.json();
    this.query = await this.processResponse(this.query);

    return this.query;
  };

  post = (url, body, force) => {
    return this.request(url, "POST", body, {}, force);
  };

  patch = (url, body) => {
    return this.request(url, "PATCH", body);
  };

  put = (url, body) => {
    return this.request(url, "PUT", body);
  };

  delete = url => {
    return this.request(url, "DELETE");
  };

  get = (url, body) => {
    return this.request(url, "GET", body);
  };
}

export default new API();
