import React, { useEffect } from "react";

import { inject, observer } from "mobx-react";
import { Router } from "@reach/router";
import { Pane } from "evergreen-ui";

import EntitiesPage from "../Entities";
import InfoblocksPage from "../Infoblocks";
import LayoutPage from "../Layout";
import PhotosPage from "../Photos";
import SitesPage from '../Sites'
import RelationsPage from "../Relations";
import UsersPage from "../Users";

import Header from '../../components/Header'
import Root from "../../components/Root";

const OverviewPage = ({ loadSites, sitesList }) => {

  useEffect(() => {
    loadSites();
  }, [loadSites])

  return (
    <>
      <Header />
      <Pane width="100vw" display="flex" paddingX="2rem" paddingTop="6rem">
        <Router>
          <SitesPage path="/" />
          <Root path="/site/:siteId">
            <LayoutPage path="/layout" />
            <LayoutPage path="/layout/:layoutId" />
            <EntitiesPage path="/entities" />
            <InfoblocksPage path="/entities/:id/infoblocks" />
            <PhotosPage path="/entities/:id/photos" />
            <RelationsPage path="/entities/:id/relations" />
            <UsersPage path="/users" />
          </Root>
        </Router>
      </Pane>
    </>
  );
};

export default inject(({ store }) => ({
  loadSites: store.sites.getAll,
  sitesList: store.sites.list
}))(observer(OverviewPage));
