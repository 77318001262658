import React, { useState } from 'react'
import { Pane, Heading, Text, Card, AddIcon } from 'evergreen-ui'
import CreateSite from '../../components/CreateSite';
import SiteCard from '../../components/SiteCard';
import { inject, observer } from 'mobx-react';

const Sites = ({ sites = [] }) => {
    const [showCreate, setShowCreate] = useState(false);

    const toggleCreateVisibility = (newState = !showCreate) => setShowCreate(newState);

    return (
        <>
            <Heading size={600} marginBottom={20}>Ваши сайты</Heading>
            <Pane display="flex" flexWrap="wrap">
                <Card onClick={toggleCreateVisibility} cursor="pointer" hoverElevation={2} activeElevation={0} width={150} height={150} border="default" display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginRight={16} marginBottom={16}>
                    <AddIcon size={32} marginBottom={10} marginTop={10} color="muted" />
                    <Text>Создать сайт</Text>
                </Card>
                {sites.map(site => <SiteCard key={site.id} {...site} />)}
            </Pane>
            <CreateSite visible={showCreate} toggleVisibility={toggleCreateVisibility} />
        </>
    )
}

export default inject(({ store }) => ({
    sites: store.sites.list,
}))(observer(Sites));
