import 'emoji-mart/css/emoji-mart.css'

import React, { useState, memo } from 'react'
import { Pane } from 'evergreen-ui'
import { Picker } from 'emoji-mart'

const PickerComponent = memo(({ visible, position, onSelect }) => {
    if (visible === null) return null;

    return (
        <Pane elevation={2} borderRadius={5} position="fixed" top={visible ? position.y : -1000} left={visible ? position.x : -1000}>
            <Picker set='apple' onSelect={onSelect} />
        </Pane>
    )
})

const useEmojiPicker = (onSelect) => {
    const [visible, setVisible] = useState(null);
    const [position, setPosition] = useState();

    const toggleVisibility = (e) => {
        const newVisible = !visible;

        setVisible(newVisible);

        if (!e) return;

        const element = e.target.getBoundingClientRect();
        setPosition({ x: element.left, y: element.bottom + 10 });
    }

    const comp = <PickerComponent visible={visible} position={position} onSelect={onSelect} />

    return [toggleVisibility, comp];
}

export default useEmojiPicker
