import React from 'react'
import { Card, Heading } from 'evergreen-ui'
import { Link } from '@reach/router'

const SiteCard = ({ id, name = '', emoji }) => {
    return (
        <Card is={Link} to={`/app/site/${id}/layout`} textDecoration="none" cursor="pointer" elevation={1} hoverElevation={2} activeElevation={0} width={300} height={150} border="default" display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginRight={16} marginBottom={16}>
            <Heading size={900}>{emoji}</Heading>
            <Heading marginTop={10}>{name}</Heading>
        </Card>
    )
}

export default SiteCard
