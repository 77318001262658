import { Heading, Pane, TagInput, TextInput, Select } from "evergreen-ui";

import React from "react";

class TagsInfoblockForm extends React.Component {
  state = {
    tags: []
  };

  componentDidMount() {
    this.setTags(this.props.item.content || []);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.item !== this.props.item)
      this.setTags(this.props.item.content);
  }

  setTags = tags => this.setState({ tags });

  static valueModifier = values => {
    return values;
  };

  render() {
    const { tags } = this.state;
    const { item } = this.props;

    return (
      <>
        <Pane marginTop={16}>
          <Heading size={100}>Title</Heading>
          <TextInput
            name="content[title]"
            defaultValue={item.title}
            placeholder="Title of the block"
            width="100%"
          />
        </Pane>

        {tags.map((tag, i) => (
          <input
            key={tag + i}
            type="hidden"
            value={tag}
            name="content[content][]"
          />
        ))}

        <Pane marginTop={16}>
          <Heading size={100}>Tags</Heading>
          <TagInput
            width="100%"
            inputProps={{ placeholder: "Add tags..." }}
            values={tags}
            onChange={this.setTags}
          />
        </Pane>

        <Pane marginTop={16}>
          <Heading size={100}>Size</Heading>
          <Select width="100%" name="content[size]" defaultValue={item.size}>
            <option value="one-third">One column</option>
            <option value="two-third">Two columns</option>
            <option value="three-third">Three columns</option>
            <option value="centered-wide">Centered wide</option>
          </Select>
        </Pane>
      </>
    );
  }
}

export default TagsInfoblockForm;
