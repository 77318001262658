import { Heading, Button, IconButton, Pane, TextInputField } from "evergreen-ui";
import { inject, observer } from "mobx-react";

import React from "react";
import { navigate } from "@reach/router";

const SignupPage = ({ user }) => {
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState({});

    React.useEffect(() => {
        if (user.authorized) navigate('/app');
    }, [user.authorized]);

    const signup = async (e) => {
        e.preventDefault();
        setLoading(true);

        console.log(new FormData(e.target).get('name'))

        const reg = await user.register(e.target);

        if (reg.errors) {
            setErrors(reg.errors);
        } else {
            navigate('/app');
        }

        setLoading(false);
    }

    return (
        <Pane
            height='100vh'
            width='100%'
            display="flex"
            alignItems="center"
            justifyContent="center"
            border="default"
        >
            <Pane
                elevation={1}
                width={400}
                padding={24}
            >
                <Pane display="flex" alignItems="center">
                    <IconButton onClick={() => navigate('/')} icon="arrow-left" height={32} />
                    <Heading size={600} marginLeft={16}>Создать аккаунт</Heading>
                </Pane>
                <Pane display="block" marginTop={20}>
                    <form onSubmit={signup}>
                        <TextInputField
                            name="name"
                            label="Как вас зовут?"
                            marginBottom={10}
                            disabled={loading}
                            placeholder="Иван"
                            type="text"
                            required
                            validationMessage={errors.name}
                            isInvalid={!!errors.name}
                        />
                        <TextInputField
                            name="email"
                            label="E-mail"
                            marginBottom={10}
                            disabled={loading}
                            placeholder="john@bread.wtf"
                            type="email"
                            required
                            validationMessage={errors.email}
                            isInvalid={!!errors.email}
                        />
                        <TextInputField
                            name="password"
                            label="Пароль"
                            marginBottom={10}
                            type="password"
                            disabled={loading}
                            placeholder="*************"
                            required
                            validationMessage={errors.password}
                            isInvalid={!!errors.password}
                        />
                        <Button disabled={loading}>Отправить</Button>
                    </form>
                </Pane>
            </Pane>
        </Pane>
    );
};

export default inject(({ store }) => ({
    user: store.user
}))(observer(SignupPage));
