import React from 'react'
import { Pane, Text, Heading, Button } from 'evergreen-ui'

const Main = () => {
    return (
        <Pane
            height='100vh'
            width='100%'
            display="flex"
            alignItems="center"
            justifyContent="center"
            border="default"
        >
            <Pane
                elevation={1}
                width={700}
                padding={24}
            >
                <Heading size={600} marginBottom={10}>Dough</Heading>
                <Text>Система управления медиа</Text>
                <Pane marginTop={20}>
                    <Button is="a" href="/login" height={48} marginRight={16}>
                        Войти
                    </Button>
                    <Button is="a" href="/signup" height={48} marginRight={16}>
                        Создать аккаунт
                    </Button>
                </Pane>
            </Pane>
        </Pane>
    )
}

export default Main;