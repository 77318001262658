import {
  Button,
  Heading,
  Pane,
  Position,
  SegmentedControl,
  SideSheet
} from "evergreen-ui";
import { inject, observer } from "mobx-react";

import PlaceInfoblockForm from "../Forms/PlaceInfoblock";
import React from "react";
import TagsInfoblockForm from "../Forms/TagsInfoblock";
import TextInfoblockForm from "../Forms/TextInfoblock";
import EmbedInfoblock from "../Forms/EmbedInfoblock";
import ImageInfoblock from "../Forms/ImageInfoblock";
import LayoutInfoblock from "../Forms/LayoutInfoblock";

const typeOptions = [
  { label: "Text", value: "text" },
  { label: "Embed", value: "embed" },
  { label: "Image", value: "image" },
  { label: "Tags", value: "tags" },
  { label: "Place", value: "place" },
  { label: "Layout", value: "layout" }
];

const getForm = type => {
  switch (type) {
    case "text":
      return TextInfoblockForm;
    case "tags":
      return TagsInfoblockForm;
    case "place":
      return PlaceInfoblockForm;
    case "embed":
      return EmbedInfoblock;
    case "image":
      return ImageInfoblock;
    case "layout":
      return LayoutInfoblock;
    default:
      return TextInfoblockForm;
  }
};

class InfoblockEdit extends React.Component {
  state = {
    type: "text"
  };

  componentDidUpdate(prevProps) {
    if (prevProps.item.type !== this.props.item.type) {
      this.setType(this.props.item.type);
    }
  }

  setType = type => this.setState({ type });

  onSubmit = async e => {
    e.preventDefault();
    const { type } = this.state;
    const { create, update, close, item } = this.props;

    const values = getForm(type).valueModifier(new FormData(e.target));

    if (item.id) await update(item.id, values);
    else await create(values);
    close();
  };

  render() {
    const { type } = this.state;
    const { visible, close, item, entityId } = this.props;

    const Form = getForm(type);

    return (
      <SideSheet
        position={Position.RIGHT}
        isShown={visible}
        onCloseComplete={close}
      >
        <form onSubmit={this.onSubmit}>
          <input type="hidden" name="entity_id" value={entityId} />
          <Pane padding="2rem">
            <Heading>Infoblock editor</Heading>
            <SegmentedControl
              name="content[type]"
              marginTop={16}
              width="100%"
              options={typeOptions}
              defaultValue={item.type}
              onChange={this.setType}
            />

            <Form item={item} />

            <Button appearance="primary" marginTop="2rem">
              Save
            </Button>
          </Pane>
        </form>
      </SideSheet>
    );
  }
}

export default inject(({ store }) => ({
  create: store.entity.blockCreate,
  update: store.entity.blockUpdate
}))(observer(InfoblockEdit));
