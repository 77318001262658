import MainPage from "./views/Main";
import LoginPage from "./views/Login";
import SignupPage from "./views/Signup";
import OverviewPage from "./views/Overview";
import PrivateRoute from "./components/PrivateRoute";
import { Provider } from "mobx-react";
import React from "react";
import { Router } from "@reach/router";
import Store from "./state/index";

const App = () => {
  return (
    <Provider store={Store}>
      <Router>
        <MainPage path="/" />
        <LoginPage path="/login" />
        <SignupPage path="/signup" />
        <PrivateRoute component={OverviewPage} path="app/*" />
      </Router>
    </Provider>
  );
};

export default App;
