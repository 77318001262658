import React, { useState } from "react";
import {
  SideSheet,
  Position,
  Paragraph,
  Pane,
  Card,
  Heading,
  Button,
  TextInputField,
  Text,
  Badge,
} from "evergreen-ui";
import { emojiIndex } from "emoji-mart";
import { inject, observer } from "mobx-react";
import useEmojiPicker from "../EmojiPicker";

const containerProps = {
  display: "flex",
  flex: "1",
  flexDirection: "column",
};

const SlugAvailability = ({ isAvailable }) => {
  if (isAvailable === null) return null;

  if (isAvailable)
    return (
      <Badge color="green" marginLeft={15}>
        Доступен
      </Badge>
    );

  return (
    <Badge color="red" marginLeft={15}>
      Занят
    </Badge>
  );
};

const CreateSite = ({ create, visible, toggleVisibility, checkSlug }) => {
  const [name, setName] = useState("");
  const [emoji, setEmoji] = useState("✍️");
  const [slug, setSlug] = useState("");
  const [slugAvailable, setSlugAvailable] = useState(null);

  const [errors, setErrors] = useState({});
  const [customEmojiSelected, setCustomEmojiSelected] = useState(false);

  const [showPicker, Picker] = useEmojiPicker((emoji) => {
    setEmoji(emoji.native);
    setCustomEmojiSelected(true);
    showPicker();
  });

  const onChangeName = (e) => {
    const { value } = e.target;

    setName(value);

    if (customEmojiSelected) return;

    const emojiFound = emojiIndex.search(value);

    if (emojiFound && emojiFound.length > 0) {
      setEmoji(emojiFound[0].native);
    }
  };

  const onChangeSlug = async (e) => {
    const { value } = e.target;

    setSlug(value);

    if (value) {
      // TODO: debounce
      const check = await checkSlug(value);

      setSlugAvailable(check);
    } else {
      setSlugAvailable(null);
    }
  };

  const readyForCreation = name && slug && emoji && slugAvailable;

  const onSubmit = async () => {
    const res = await create(name, slug, emoji);

    if (res.errors) {
      setErrors(res.errors);

      return;
    }

    toggleVisibility(false);
    setName("");
    setSlug("");
    setEmoji("🐸");
    setSlugAvailable(null);
    return;
  };

  return (
    <SideSheet
      position={Position.LEFT}
      isShown={visible}
      onCloseComplete={() => toggleVisibility(false)}
      containerProps={containerProps}
    >
      <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
        <Pane padding={16} borderBottom="muted">
          <Heading size={600}>Создание сайта</Heading>
          <Paragraph size={400} color="muted">
            Сайты объединяют свои собственные лейауты, элементы и настройки
          </Paragraph>
        </Pane>
      </Pane>
      <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>
        <Card
          backgroundColor="white"
          elevation={0}
          height={240}
          display="flex"
          padding="2rem"
        >
          <Button
            border="1px solid #DCDCDC !important"
            width="100px"
            height="100px"
            display="flex"
            justifyContent="center"
            fontSize={40}
            alignItems="center"
            marginRight={20}
            appearance="minimal"
            onClick={showPicker}
          >
            {emoji}
          </Button>
          <Pane>
            <TextInputField
              label="Название сайта"
              required
              placeholder="Пирожки"
              onChange={onChangeName}
              value={name}
              width={245}
              validationMessage={errors.name}
            />
            <Pane display="flex" alignItems="center">
              <TextInputField
                width={170}
                label="Адрес сайта"
                required
                placeholder="cakes"
                value={slug}
                onChange={onChangeSlug}
                validationMessage={errors.slug}
              />
              <Text marginLeft={10}>
                .mediakit.dev <SlugAvailability isAvailable={slugAvailable} />
              </Text>
            </Pane>
          </Pane>
        </Card>
        <Pane textAlign="center" marginTop={20}>
          <Button
            onClick={onSubmit}
            disabled={!readyForCreation}
            appearance="primary"
            height={40}
          >
            Создать
          </Button>
        </Pane>
      </Pane>
      {Picker}
    </SideSheet>
  );
};

export default inject(({ store }) => ({
  checkSlug: store.sites.checkSlug,
  create: store.sites.create,
}))(observer(CreateSite));
