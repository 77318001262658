import { inject, observer } from 'mobx-react';

import React from 'react';
import { Spinner } from 'evergreen-ui';
// import { navigate } from '@reach/router';

const PrivateRoute = ({
    component: Component,
    location,
    user: { authorized, loaded },
    ...rest
}) => {
    if (!loaded) {
        return <Spinner />;
    }

    // if (!authorized && location.pathname !== "/") {
    //   navigate("/login");
    //   return null;
    // }

    return <Component {...rest} />;
};

export default inject(({ store }) => ({ user: store.user }))(
    observer(PrivateRoute)
);
