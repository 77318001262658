import {
  Button,
  Heading,
  Pane,
  SelectMenu,
  TextInput,
  Textarea
} from "evergreen-ui";
import { inject, observer } from "mobx-react";

import React from "react";

class PlaceInfoblockForm extends React.Component {
  state = {
    tags: [],
    children: null,
    childrenName: null
  };

  componentDidMount() {
    this.setTags(this.props.item.content || []);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.item !== this.props.item)
      this.setTags(this.props.item.content);
  }

  setTags = tags => this.setState({ tags });

  addChildren = item => {
    this.setState({
      children: item.value,
      childrenName: item.label
    });
  };

  removeChildren = () => {
    this.setState({
      children: null,
      childrenName: null
    });
  };

  static valueModifier = values => {
    return values;
  };

  render() {
    const { children, childrenName } = this.state;
    const { entities, item } = this.props;

    return (
      <>
        <Pane marginTop={16}>
          <Heading size={100}>Title</Heading>
          <TextInput
            name="content[title]"
            defaultValue={item.title}
            placeholder="Title of the block"
            width="100%"
          />
        </Pane>

        <Pane marginTop={16}>
          <Heading size={100}>Description</Heading>
          <Textarea
            name="content[description]"
            defaultValue={
              typeof item.description === "object"
                ? item.description.join("\n\n")
                : item.description
            }
            placeholder="Paragraph"
            width="100%"
          />
        </Pane>

        <input type="hidden" name="content[children_id]" value={children} />

        <Pane marginTop={16}>
          <Heading size={200}>Children</Heading>
          <SelectMenu
            title="Children"
            options={entities}
            selected={children}
            onSelect={this.addChildren}
            onDeselect={this.removeChildren}
            isMultiSelect={false}
          >
            <Button type="button">
              {children ? `${childrenName}` : "Select entity..."}
            </Button>
          </SelectMenu>
        </Pane>
      </>
    );
  }
}

export default inject(({ store }) => ({
  getById: store.entity.loadById,
  entities: Object.keys(store.entity.ids).map(key => ({
    label: store.entity.ids[key].name,
    value: parseInt(key),
    ...store.entity.ids[key]
  }))
}))(observer(PlaceInfoblockForm));
