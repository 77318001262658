import API from "../services/API";
import Entity from "./Entity";
import Layout from "./Layout";
import User from "./User";
import Sites from "./Sites";

class Store {
  constructor(API) {
    this.user = new User(API);
    this.layout = new Layout(API);
    this.entity = new Entity(API);
    this.sites = new Sites(API);
  }
}

const instance = new Store(API);

export default instance;
