import { action, observable } from "mobx";

import State from "./bases/State";

class Layout extends State {
  @observable list = [];
  lastSiteId = null;
  lastId = null;
  loaded = false;

  @action
  get = async (siteId = this.lastSiteId, id = "", force = false) => {
    if (this.lastSiteId === siteId && this.lastId === id && !force && this.loaded) return this.list;

    this.lastId = id;
    this.lastSiteId = siteId;
    this.loaded = false;
    try {
      this.list = await this.API.get(`/api/sites/${siteId}/layout/${id}`);
      this.loaded = true;
    } catch (error) {
      // handle error
    }

    return this.list;
  };

  create = async form => {
    try {
      await this.API.post("/api/layout", form);

      await this.get(this.lastSiteId, this.lastId, true);

      return true;
    } catch (error) {
      // handle error
      return false;
    }
  };
  update = async (id, form) => {
    try {
      await this.API.post(`/api/layout/${id}`, form);
      await this.get(this.lastSiteId, this.lastId, true);

      return true;
    } catch (error) {
      // handle error
      return false;
    }
  };

  updateOrder = async (id, newOrder) => {
    try {
      await this.API.get(`/api/layout/${id}/${newOrder}`);
      await this.get(this.lastSiteId, this.lastId, true);

      return true;
    } catch (error) {
      // handle error
      return false;
    }
  };

  @action
  delete = async id => {
    if (!id) return false;

    if (window.confirm("Are you sure?")) {
      try {
        await this.API.delete(`/api/layout/${id}`);
        await this.get(this.lastSiteId, this.lastId, true);

        return true;
      } catch (error) {
        // handle error
        return false;
      }
    }
  };

  setAsMainPage = async id => {
    if (!id) return false;

    if (
      window.confirm("Are you sure you want to set this template as main page?")
    ) {
      try {
        await this.API.get(`/api/layout/${id}/setMain`);
        await this.get(this.lastSiteId, "", true);

        return true;
      } catch (error) {
        // handle error
        return false;
      }
    }
  };
}

export default Layout;
