import { Button, Heading, Pane, SelectMenu } from "evergreen-ui";
import { inject, observer } from "mobx-react";

import React from "react";

const filterWith = (list, toExclude) => {
  if (!Array.isArray(toExclude) && Object.keys(toExclude).length === 0)
    return [];

  let excluded = toExclude;
  if (!Array.isArray(toExclude)) {
    excluded = [];
    Object.keys(toExclude).forEach(key => {
      excluded = [...toExclude[key], ...excluded];
    });
  }

  const ids = excluded.map(item => item.id || item);

  return list.filter(item => !ids.includes(item.id));
};

class RelationsPage extends React.Component {
  state = {
    entity: null,
    children: [],
    parents: []
  };

  componentDidMount() {
    this.load();
  }

  load = async () => {
    const { id, getById } = this.props;

    const entity = await getById(id);

    this.setState({ entity });
  };

  remove = async (type, entityId) => {
    const { removeRelation } = this.props;

    await removeRelation(type, entityId);
    await this.load();
  };

  addChildren = item => {
    this.setState(prev => ({
      children: [item.value, ...prev.children]
    }));
  };

  removeChildren = item => {
    this.setState(prev => ({
      children: prev.children.filter(child => child !== item.value)
    }));
  };

  addParent = item => {
    this.setState(prev => ({
      parents: [item.value, ...prev.parents]
    }));
  };

  removeParent = item => {
    this.setState(prev => ({
      parents: prev.parents.filter(child => child !== item.value)
    }));
  };

  saveRelations = async () => {
    const { children, parents } = this.state;
    const { saveRelations } = this.props;

    await saveRelations(children, parents);
    await this.load();

    this.setState({ children: [], parents: [] });
  };

  render() {
    const { entities } = this.props;
    const { entity, parents, children } = this.state;

    return (
      <div>
        {entity && (
          <Pane key={entity.id} marginBottom={32}>
            <Heading size={600}>{entity.name}</Heading>
            <Pane
              border="default"
              padding="1rem"
              marginTop="1rem"
              marginBottom="1rem"
              display="flex"
            >
              <Pane marginRight="2rem">
                <Heading size={200}>Children</Heading>
                <SelectMenu
                  title="Children"
                  options={filterWith(entities, entity.children)}
                  selected={children}
                  onSelect={this.addChildren}
                  onDeselect={this.removeChildren}
                  isMultiSelect={true}
                >
                  <Button>
                    {children.length
                      ? `${children.length} selected`
                      : "Select children..."}
                  </Button>
                </SelectMenu>
              </Pane>

              <Pane marginRight="2rem">
                <Heading size={200}>Parents</Heading>
                <SelectMenu
                  title="Parents"
                  options={filterWith(entities, entity.parents)}
                  selected={parents}
                  onSelect={this.addParent}
                  onDeselect={this.removeParent}
                  isMultiSelect={true}
                >
                  <Button>
                    {parents.length
                      ? `${parents.length} selected`
                      : "Select children..."}
                  </Button>
                </SelectMenu>
              </Pane>

              <Pane marginRight="2rem" paddingTop="1rem">
                <Button onClick={this.saveRelations} appearance="primary">
                  Save
                </Button>
              </Pane>
            </Pane>
            <Heading>Children</Heading>
            <Pane display="flex" flexDirection="row" flexWrap="wrap">
              {entity &&
                entity.children &&
                Object.keys(entity.children).map(key =>
                  entity.children[key].map(child => (
                    <Pane
                      key={child.id}
                      marginBottom={16}
                      marginTop={16}
                      border="default"
                      padding={16}
                    >
                      <Heading size={200}>{child.name}</Heading>
                      <Button
                        iconBefore="trash"
                        marginRight={16}
                        appearance="minimal"
                        intent="danger"
                        onClick={() => this.remove("children", child.id)}
                      >
                        Remove
                      </Button>
                    </Pane>
                  ))
                )}
            </Pane>
            <Heading>Parents</Heading>
            <Pane display="flex" flexDirection="row" flexWrap="wrap">
              {entity &&
                entity.parents &&
                entity.parents.map(child => (
                  <Pane
                    key={child.id}
                    marginBottom={16}
                    marginTop={16}
                    border="default"
                    padding={16}
                  >
                    <Heading size={200}>{child.name}</Heading>
                    <Button
                      iconBefore="trash"
                      marginRight={16}
                      appearance="minimal"
                      intent="danger"
                      onClick={() => this.remove("parent", child.id)}
                    >
                      Remove
                    </Button>
                  </Pane>
                ))}
            </Pane>
          </Pane>
        )}
      </div>
    );
  }
}

export default inject(({ store }) => ({
  getById: store.entity.loadById,
  entities: Object.keys(store.entity.ids).map(key => ({
    label: store.entity.ids[key].name,
    value: parseInt(key),
    ...store.entity.ids[key]
  })),
  saveRelations: store.entity.saveRelations,
  removeRelation: store.entity.removeRelation
}))(observer(RelationsPage));
