import {
  Button,
  Heading,
  Pane,
  Position,
  SegmentedControl,
  SelectMenu,
  SideSheet,
  TextInput
} from "evergreen-ui";
import { inject, observer } from "mobx-react";

import React from "react";

const typeOptions = [
  { label: "Template", value: "template" },
  { label: "Section", value: "section" },
  { label: "Children", value: "children" }
];

class LayoutItemEdit extends React.Component {
  state = {
    parent: null,
    entity: null,
  };

  componentDidMount() {
    this.setEntityAndParent();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.item !== this.props.item && this.props.item)
      this.setEntityAndParent();
  }

  setEntityAndParent = () => {
    const { parent, entity } = this.state;
    const { item } = this.props;

    if (!item) return;

    let entityUpd = null,
      parentUpd = null;
    if (item.parent && parent !== item.parent.id)
      parentUpd = { label: item.parent.title, value: item.parent.id };
    if (item.entity && entity !== item.entity.id)
      entityUpd = { label: item.entity.name, value: item.entity.id };

    this.setState({
      parent: parentUpd,
      entity: entityUpd
    });
  };

  setParent = parent => {
    this.setState({ parent });
  };

  setEntity = entity => {
    this.setState({ entity });
  };

  onSubmit = async e => {
    e.preventDefault();
    const { create, update, close, item } = this.props;
    const { entity, parent } = this.state;

    const values = new FormData(e.target);

    entity && values.append("entity_id", entity.value);
    parent && values.append("parent_id", parent.value);
    values.append("site_id", item.parent.siteId);

    if (item.id) await update(item.id, values);
    else await create(values);

    close();
  };

  render() {
    const { visible, layout, entities, close, item } = this.props;
    const { parent, entity } = this.state;

    return (
      <SideSheet
        position={Position.RIGHT}
        isShown={visible}
        onCloseComplete={close}
      >
        <Pane padding="2rem">
          <Heading>Layout editor</Heading>
          <form onSubmit={this.onSubmit}>
            <SegmentedControl
              name="type"
              marginTop={16}
              width="100%"
              options={typeOptions}
              defaultValue={item.type}
            />

            <Pane marginTop={16} display="flex">
              <Pane marginRight={8}>
                <Heading size={100}>Block name</Heading>
                <TextInput
                  name="title"
                  placeholder="Enter name..."
                  defaultValue={item.title}
                />
              </Pane>
              <Pane marginLeft={8}>
                <Heading size={100}>Order</Heading>
                <TextInput
                  type="number"
                  name="order"
                  placeholder="Enter order..."
                  defaultValue={item.order}
                />
              </Pane>
            </Pane>

            <Pane marginTop={16}>
              <Pane>
                <Heading size={100}>Parent layout</Heading>
                <SelectMenu
                  title="Select parent"
                  options={layout.map(item => ({
                    label: item.title,
                    value: item.id
                  }))}
                  onSelect={this.setParent}
                  selected={parent && parent.value}
                >
                  <Button type="button">
                    {parent ? parent.label : "Select parent..."}
                  </Button>
                </SelectMenu>
              </Pane>
              <Pane marginTop={16}>
                <Heading size={100}>Entity</Heading>
                <SelectMenu
                  title="Select entity"
                  onSelect={this.setEntity}
                  options={entities.map(entity => ({
                    label: entity.name,
                    value: entity.id
                  }))}
                  selected={entity && entity.value}
                >
                  <Button type="button">
                    {entity ? entity.label : "Select parent..."}
                  </Button>
                </SelectMenu>
              </Pane>
            </Pane>
            <Button appearance="primary" marginTop="2rem">
              Save
            </Button>
          </form>
        </Pane>
      </SideSheet>
    );
  }
}

export default inject(({ store }) => ({
  create: store.layout.create,
  update: store.layout.update,
  layout: store.layout.list,
  entities: Object.keys(store.entity.ids).map(id => store.entity.ids[id])
}))(observer(LayoutItemEdit));
