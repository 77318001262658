import { Button, Pane } from "evergreen-ui";
import { inject, observer } from "mobx-react";

import React from "react";
import UserEdit from "../../components/UserEdit";

const UsersPage = ({ users, load, destroy }) => {
  const [createVisible, setCreateVisible] = React.useState(false);
  const [toEdit, setToEdit] = React.useState({});
  //   const [selectedIndex, setSelectedIndex] = React.useState(0);
  load();

  return (
    <div>
      <Button
        iconBefore="add"
        marginBottom={16}
        onClick={() => setCreateVisible(true)}
      >
        Add user
      </Button>
      <Pane display="flex" marginTop={16} maxWidth="75vw" flexWrap="wrap">
        {users.map(user => (
          <Pane key={user.id} width="100%" border="default" padding="1rem">
            <Button marginRight={16} appearance="minimal">
              {user.name}
            </Button>
            <Button marginRight={16} appearance="minimal" disabled>
              {user.email}
            </Button>
            <Button
              marginRight={12}
              iconBefore="edit"
              onClick={() => {
                setToEdit(user);
                setCreateVisible(true);
              }}
            >
              Edit
            </Button>
            <Button
              marginRight={12}
              iconBefore="trash"
              intent="danger"
              onClick={() => destroy(user.id)}
            >
              Delete...
            </Button>
          </Pane>
        ))}
      </Pane>
      <UserEdit
        visible={createVisible}
        item={toEdit}
        close={() => {
          setToEdit({});
          setCreateVisible(false);
        }}
      />
    </div>
  );
};

export default inject(({ store }) => ({
  users: store.user.list,
  load: store.user.getList,
  destroy: store.user.delete
}))(observer(UsersPage));
