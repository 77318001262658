import { Button, Heading, Pane, Paragraph } from "evergreen-ui";
import { inject, observer } from "mobx-react";

import InfoblockEdit from "../../components/InfoblockEdit";
import React from "react";

const getSize = (size, type) => {
  if (["place"].includes(type)) return 932;

  if (!size || size === "one-third") return 300;
  if (size === "two-third") return 616;

  return 932;
};

const InfoblocksPage = ({ getById, load, id, blocks, remove }) => {
  const [createVisible, setCreateVisible] = React.useState(false);
  const [toEdit, setToEdit] = React.useState({});

  load();

  const entity = getById(id);

  return (
    <div>
      {entity && (
        <Pane key={entity.id} marginBottom={32}>
          <Heading size={600}>{entity.name}</Heading>
          <Button
            iconBefore="add"
            marginTop={16}
            marginBottom={16}
            onClick={() => setCreateVisible(true)}
          >
            Add block
          </Button>
          <Pane display="flex" flexWrap="wrap">
            {blocks &&
              blocks.map(block => (
                <Pane
                  key={block.id}
                  marginBottom={16}
                  marginTop={16}
                  marginRight={16}
                  border="default"
                  padding={16}
                  width={getSize(block.size, block.type)}
                >
                  {block.title && (
                    <Heading size={400} marginBottom={16}>
                      {block.title}
                    </Heading>
                  )}
                  {block.type !== "place" &&
                    block.content &&
                    typeof block.content === "object" &&
                    block.content.map(item => (
                      <Paragraph key={item} marginBottom={10}>
                        {item}
                      </Paragraph>
                    ))}
                  {block.description && (
                    <Paragraph marginBottom={10}>{block.description}</Paragraph>
                  )}
                  {block.embed && (
                    <Pane dangerouslySetInnerHTML={{ __html: block.embed }} />
                  )}
                  {block.image_url && (
                    <img
                      alt="info block"
                      src={`${process.env.REACT_APP_API_ENDPOINT}/${block.image_url}`}
                      width={200}
                    />
                  )}
                  {block.children && (
                    <Pane
                      marginBottom={16}
                      marginTop={16}
                      border="default"
                      padding={16}
                      display="flex"
                    >
                      <Pane paddingRight={16}>
                        {block.children.wrapper_url ? (
                          <img
                            alt="wrapper"
                            width={80}
                            height={80}
                            src={`${process.env.REACT_APP_API_ENDPOINT}/${block.children.wrapper_url}`}
                          />
                        ) : (
                          block.children.mark
                        )}
                      </Pane>
                      <Pane>
                        <Heading size={400} marginBottom={16}>
                          {block.children.name}
                        </Heading>
                        <Paragraph marginBottom={10}>
                          {block.children.short_description}
                        </Paragraph>
                        <Paragraph marginBottom={10}>
                          {block.description}
                        </Paragraph>
                      </Pane>
                    </Pane>
                  )}
                  <>
                    <Button
                      iconBefore="edit"
                      marginRight={16}
                      appearance="minimal"
                      onClick={() => {
                        setToEdit(block);
                        setCreateVisible(true);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      iconBefore="trash"
                      marginRight={16}
                      appearance="minimal"
                      intent="danger"
                      onClick={() => remove(block.id)}
                    >
                      Remove
                    </Button>
                  </>
                </Pane>
              ))}
          </Pane>
        </Pane>
      )}
      <InfoblockEdit
        visible={createVisible}
        item={toEdit}
        entityId={id}
        close={() => {
          setToEdit({});
          setCreateVisible(false);
        }}
      />
    </div>
  );
};

export default inject(({ store }) => ({
  getById: id => {
    store.entity.getBlocks(id);
    return store.entity.byId(id);
  },
  blocks: store.entity.blocks,
  load: store.entity.get,
  remove: store.entity.deleteBlock
}))(observer(InfoblocksPage));
