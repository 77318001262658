import { action, observable } from "mobx";

import State from "./bases/State";

class Sites extends State {
    @observable list = [];
    lastId = null;
    loaded = false;

    @action
    getAll = async (force = false) => {
        if (!force && this.loaded) return this.list;

        this.loaded = false;
        try {
            this.list = await this.API.get(`/api/sites`);
            this.loaded = true;
        } catch (error) {
            // handle error
        }

        return this.list;
    };

    checkSlug = async (slug) => {
        try {
            const res = await this.API.get(`/api/sites/checkSlug?slug=${slug}`);

            if (res.status === 'free') return true;
        } catch (error) {
            // error
        }

        return false;
    }

    create = async (name, slug, emoji) => {
        const reg = await this.API.post(
            "/api/sites/create",
            {
                name, slug, emoji
            },
            true
        );
        await this.getAll(true);

        return reg;
    }
}

export default Sites;
