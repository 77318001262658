export const selectFiles = () => {
  if (typeof window === "undefined") return Promise.reject();

  return new Promise(resolve => {
    const input = window.fileUploadInput || document.createElement("input");

    window.fileUploadInput = input;
    input.type = "file";
    input.multiple = true;
    input.click();

    input.onchange = () => {
      resolve(createFormData(input));

      delete window.fileUploadInput;
    };
  });
};

export const createFormData = input => {
  const data = new FormData();

  Object.keys(input.files).forEach(key => {
    data.append("photos[]", input.files[key]);
  });

  return data;
};
