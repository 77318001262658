import {
  Button,
  Heading,
  Pane,
  Position,
  SideSheet,
  TextInput
} from "evergreen-ui";
import { inject, observer } from "mobx-react";

import React from "react";

class UserEdit extends React.Component {
  onSubmit = async e => {
    e.preventDefault();
    const { create, update, close, item } = this.props;

    const values = new FormData(e.target);

    if (item.id) await update(item.id, values);
    else await create(values);

    close();
  };

  render() {
    const { visible, close, item } = this.props;

    return (
      <SideSheet
        position={Position.RIGHT}
        isShown={visible}
        onCloseComplete={close}
      >
        <Pane padding="2rem">
          <Heading>User editor</Heading>
          <form onSubmit={this.onSubmit}>
            <Pane marginTop={16}>
              <Pane marginTop={8} width="100%">
                <Heading size={100}>Name</Heading>
                <TextInput
                  name="name"
                  placeholder="Enter name..."
                  defaultValue={item.name}
                />
              </Pane>
              <Pane marginTop={8} width="100%">
                <Heading size={100}>E-mail</Heading>
                <TextInput
                  name="email"
                  type="email"
                  placeholder="Enter email..."
                  defaultValue={item.email}
                />
              </Pane>
              <Pane marginTop={8} width="100%">
                <Heading size={100}>Password</Heading>
                <TextInput
                  name="password"
                  type="password"
                  placeholder="Enter password..."
                />
              </Pane>
            </Pane>
            <Button appearance="primary" marginTop="2rem">
              Save
            </Button>
          </form>
        </Pane>
      </SideSheet>
    );
  }
}

export default inject(({ store }) => ({
  create: store.user.create,
  update: store.user.update
}))(observer(UserEdit));
