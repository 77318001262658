import {
  Button,
  FilePicker,
  Heading,
  Pane,
  Position,
  SegmentedControl,
  SideSheet,
  Switch,
  TagInput,
  TextInput,
  Textarea
} from "evergreen-ui";
import { inject, observer } from "mobx-react";

import React from "react";

const typeOptions = [
  { label: "City", value: "city" },
  { label: "Dish", value: "dish" },
  { label: "Place", value: "place" },
  { label: "Collection", value: "collection" },
  { label: "Page", value: "page" }
];

class EntityItemEdit extends React.Component {
  state = {
    tags: [],
    links: []
  };

  componentDidMount() {
    this.setTags();
    this.setLinks();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.item.tags !== this.props.item.tags) this.setTags();
    if (prevProps.item.links !== this.props.item.links) this.setLinks();
  }

  setTags = () => {
    const { item } = this.props;

    if (!item.tags) return;

    this.setState({
      tags: Array.isArray(item.tags) ? item.tags : JSON.parse(item.tags)
    });
  };

  setLinks = () => {
    const { item } = this.props;

    if (!item.links) return;

    this.setState({
      links: Array.isArray(item.links) ? item.links : JSON.parse(item.links)
    });
  };

  updateTags = tags => {
    this.setState({ tags });
  };

  updateLinks = links => {
    this.setState({ links });
  };

  onSubmit = async e => {
    e.preventDefault();
    const { create, update, close, item, siteId } = this.props;

    const values = new FormData(e.target);

    values.append("tags", JSON.stringify(this.state.tags));
    values.append("links", JSON.stringify(this.state.links));
    values.append("site_id", siteId)

    if (item.id) await update(item.id, values);
    else await create(values);
    close();
  };

  render() {
    const { visible, close, item } = this.props;
    const { tags, links } = this.state;

    return (
      <SideSheet
        position={Position.RIGHT}
        isShown={visible}
        onCloseComplete={close}
      >
        <form onSubmit={this.onSubmit}>
          <Pane padding="2rem">
            <Heading>Entity editor</Heading>
            <SegmentedControl
              name="type"
              marginTop={16}
              width="100%"
              options={typeOptions}
              defaultValue={item.type}
            />

            <Pane marginTop={16} display="flex">
              <Pane marginRight={8}>
                <Heading size={100}>Entity name</Heading>
                <TextInput
                  name="name"
                  defaultValue={item.name}
                  placeholder="Enter name..."
                />
              </Pane>
              <Pane marginRight={8}>
                <Heading size={100}>Entity mark</Heading>
                <TextInput
                  name="mark"
                  defaultValue={item.mark}
                  placeholder="Emoji"
                />
              </Pane>
            </Pane>
            <Pane marginTop={16}>
              <Heading size={100}>Short description</Heading>
              <TextInput
                name="short_description"
                defaultValue={item.short_description}
                placeholder="Brief description"
                width="100%"
              />
            </Pane>

            <Pane marginTop={16}>
              <Heading size={100}>Description</Heading>
              <Textarea
                name="description"
                defaultValue={item.description}
                placeholder="Full description"
                width="100%"
              />
            </Pane>

            <Pane marginTop={16}>
              <Heading size={100}>Wrapper</Heading>
              <FilePicker
                name="wrapper_url"
                placeholder="Select file"
                width="100%"
              />
            </Pane>

            <Pane marginTop={16}>
              <Heading size={100}>Editorial pick</Heading>
              <Switch
                name="editorial_pick"
                defaultChecked={item.editorial_pick}
                placeholder="Select file"
                width="100%"
              />
            </Pane>

            <Pane marginTop={16}>
              <Heading size={100}>Tags</Heading>
              <TagInput
                name="tags"
                width="100%"
                values={tags}
                defaultValue={item.tags}
                onChange={this.updateTags}
              />
            </Pane>

            <Pane marginTop={16}>
              <Heading size={100}>Links</Heading>
              <TagInput
                name="links"
                width="100%"
                values={links}
                defaultValue={item.links}
                onChange={this.updateLinks}
              />
            </Pane>

            <Pane marginTop={16}>
              <Heading size={100}>Visible</Heading>
              <Switch
                defaultChecked={item.visible}
                name="visible"
                width="100%"
              />
            </Pane>

            <Button appearance="primary" marginTop="2rem">
              Save
            </Button>
          </Pane>
        </form>
      </SideSheet>
    );
  }
}

export default inject(({ store }) => ({
  create: store.entity.create,
  update: store.entity.update
}))(observer(EntityItemEdit));
