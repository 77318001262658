import { Heading, Pane, TextInput } from "evergreen-ui";

import React from "react";

class LayoutInfoblock extends React.Component {
  static valueModifier = values => {
    return values;
  };

  render() {
    const { item } = this.props;

    return (
      <>
        <Pane marginTop={16}>
          <Heading size={100}>Layout ID</Heading>
          <TextInput
            name="content[layout_id]"
            defaultValue={item.layout_id}
            placeholder="ID of the layout"
            width="100%"
          />
        </Pane>
      </>
    );
  }
}

export default LayoutInfoblock;
