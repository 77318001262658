import { action, observable } from "mobx";

import State from "./bases/State";

class Layout extends State {
  @observable list = {};
  @observable ids = {};
  @observable blocks = [];
  loading = false;
  loaded = false;
  lastId = null;
  lastSiteId = "";

  constructor(props) {
    super(props);

    this.get();
  }

  create = async (form) => {
    try {
      await this.API.post("/api/entities", form);

      await this.get(true);

      return true;
    } catch (error) {
      // handle error
      return false;
    }
  };

  blockCreate = async (form) => {
    try {
      await this.API.post("/api/infoblocks", form);
      await this.getBlocks(this.lastId, true);

      return true;
    } catch (error) {
      // handle error
      return false;
    }
  };

  update = async (id, form) => {
    try {
      await this.API.post(`/api/entities/${id}`, form);
      await this.get(true);

      return true;
    } catch (error) {
      // handle error
      return false;
    }
  };

  blockUpdate = async (id, form) => {
    try {
      await this.API.post(`/api/infoblocks/${id}`, form);
      await this.getBlocks(this.lastId, true);

      return true;
    } catch (error) {
      // handle error
      return false;
    }
  };

  @action
  getBlocks = async (id, force) => {
    if (this.lastId === id && !force) return this.blocks;

    try {
      this.blocks = await this.API.get(`/api/entities/${id}/blocks`);
      this.lastId = id;
    } catch (error) {
      // handle error
      return false;
    }

    return this.blocks;
  };

  byId = (id) => this.ids[id];

  @action
  loadById = async (id) => {
    try {
      const entity = await this.API.get(`/api/entities/${id}`);

      this.ids[id] = entity;
      this.lastId = id;

      return entity;
    } catch (error) {
      // ...
      return false;
    }
  };

  @action
  get = async (siteId = this.lastSiteId, force = false) => {
    if (
      ((Object.keys(this.list).length > 0 && !force) ||
        (this.loaded && !force)) &&
      this.lastSiteId === siteId
    )
      return this.list;

    try {
      this.loading = true;
      this.loaded = false;
      this.list = await this.API.get(`/api/entities/all?siteId=${siteId}`);
      this.loading = false;
      this.loaded = true;
      this.lastSiteId = siteId;

      const ids = {};

      Object.keys(this.list).forEach((key) => {
        this.list[key].forEach((item) => (ids[item.id] = item));
      });

      this.ids = ids;
    } catch (error) {
      // handle error
    }

    return this.list;
  };

  @action
  delete = async (id) => {
    if (!id) return false;

    if (window.confirm("Are you sure?")) {
      try {
        await this.API.delete(`/api/entities/${id}`);
        await this.get(true);

        return true;
      } catch (error) {
        // handle error
        return false;
      }
    }
  };

  @action
  deleteBlock = async (id) => {
    if (!id) return false;

    if (window.confirm("Are you sure?")) {
      try {
        await this.API.delete(`/api/infoblocks/${id}`);
        await this.getBlocks(this.lastId, true);

        return true;
      } catch (error) {
        // handle error
        return false;
      }
    }
  };

  @action
  deletePhoto = async (id) => {
    if (!id) return false;

    if (window.confirm("Are you sure?")) {
      try {
        await this.API.delete(`/api/photos/${id}`);
        await this.loadById(this.lastId);

        return true;
      } catch (error) {
        // handle error
        return false;
      }
    }
  };

  storePhotos = async (id, form) => {
    try {
      await this.API.post(`/api/entities/${id}/photos`, form);
      await this.loadById(this.lastId);

      return true;
    } catch (error) {
      // handle error
      return false;
    }
  };

  saveRelations = async (children, parents, id = this.lastId) => {
    try {
      await this.API.post(`/api/entities/${id}/attachRelations`, {
        children,
        parents,
      });

      return true;
    } catch (error) {
      // handle error
      return false;
    }
  };

  removeRelation = async (type, entityId, id = this.lastId) => {
    if (window.confirm("Are you sure?")) {
      try {
        await this.API.post(`/api/entities/${id}/detachRelations`, {
          type,
          entityId,
        });

        return true;
      } catch (error) {
        // handle error
        return false;
      }
    }
  };
}

export default Layout;
