import { Heading, Pane, TextInput, Textarea, Select } from "evergreen-ui";

import React from "react";

class EmbedInfoblock extends React.Component {
  static valueModifier = values => {
    const paragraphs = values
      .get("content[content]")
      .split(/\r?\n/)
      .filter(item => item.length > 0);
    values.delete("content[content]");
    paragraphs.forEach(item => {
      values.append("content[content][]", item);
    });

    return values;
  };

  render() {
    const { item } = this.props;

    return (
      <>
        <Pane marginTop={16}>
          <Heading size={100}>Title</Heading>
          <TextInput
            name="content[title]"
            defaultValue={item.title}
            placeholder="Title of the block"
            width="100%"
          />
        </Pane>

        <Pane marginTop={16}>
          <Heading size={100}>Text content</Heading>
          <Textarea
            name="content[content]"
            defaultValue={
              typeof item.content === "object" && item.content
                ? item.content.join("\n\n")
                : item.content
            }
            placeholder="Paragraph"
            width="100%"
          />
        </Pane>

        <Pane marginTop={16}>
          <Heading size={100}>Embed content</Heading>
          <Textarea
            name="content[embed]"
            defaultValue={item.embed}
            placeholder="HTML code"
            width="100%"
          />
        </Pane>

        <Pane marginTop={16}>
          <Heading size={100}>Size</Heading>
          <Select width="100%" name="content[size]" defaultValue={item.size}>
            <option value="one-third">One column</option>
            <option value="two-third">Two columns</option>
            <option value="three-third">Three columns</option>
            <option value="centered-wide">Centered wide</option>
          </Select>
        </Pane>
      </>
    );
  }
}

export default EmbedInfoblock;
