import { Heading, Button, IconButton, Pane, TextInputField } from "evergreen-ui";
import { inject, observer } from "mobx-react";

import React from "react";
import { navigate } from "@reach/router";

const LoginPage = ({ user }) => {
  const [loading, setLoading] = React.useState(false);
  const [login, setLogin] = React.useState("");
  const [password, setPassword] = React.useState("");

  React.useEffect(() => {
    if (user.authorized) navigate('/app');
  }, [user.authorized]);

  const auth = async () => {
    setLoading(true);

    const loggedUser = await user.authenticate(login, password);
    if (loggedUser.id) navigate("/app");
    else alert("Incorrect data");

    setLoading(false);
  };

  return (
    <Pane
      height='100vh'
      width='100%'
      display="flex"
      alignItems="center"
      justifyContent="center"
      border="default"
    >
      <Pane
        elevation={1}
        width={400}
        padding={24}
      >
        <Pane display="flex" alignItems="center">
          <IconButton onClick={() => navigate('/')} icon="arrow-left" height={32} />
          <Heading size={600} marginLeft={16}>Войти</Heading>
        </Pane>
        <Pane display="block" marginTop={20}>
          <TextInputField
            label="E-mail"
            marginBottom={10}
            onChange={e => setLogin(e.target.value)}
            value={login}
            disabled={loading}
            placeholder="john@bread.wtf"
          />
          <TextInputField
            label="Пароль"
            marginBottom={10}
            type="password"
            onChange={e => setPassword(e.target.value)}
            value={password}
            disabled={loading}
            placeholder="********"
          />
          <Button onClick={auth} disabled={loading}>Войти</Button>
        </Pane>
      </Pane>
    </Pane>
  );
};

export default inject(({ store }) => ({
  user: store.user
}))(observer(LoginPage));
